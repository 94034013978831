<template>
  <tr :key="item.id">
    <td>
      <router-link target="_blank" :to="`/products?variantId=${item.orderItem.variantId}`">
        {{ productName }}
        <template v-if="sku"><br><small>SKU: {{ sku }}</small></template>
      </router-link>
      <br><strong>Motif:</strong> {{ item.reasonText }}
    </td>
    <td class="text-center">{{ quantity }}</td>
    <td>{{ status }}</td>
    <td>
      <template v-if="areActionsAvailable">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click.prevent="handleStatusChange('returnDenied')"
        >
          Refuser
        </button>
        <button
          type="button"
          class="ml-2 btn btn-primary btn-sm"
          @click.prevent="handleStatusChange('pendingReturn')"
        >
          Accepter
        </button>
      </template>
      <template v-else-if="isPendingReturn">
        <button
          type="button"
          class="btn btn-info btn-sm"
          @click.prevent="handleStatusChange('returnConfirmed')"
        >
          Confirmer la réception
        </button>
      </template>
    </td>
  </tr>
</template>

<script>
import { get } from 'lodash-es'
import formatsCurrency from '@/mixins/formatsCurrency'
import returnStatus from '@/mixins/returnStatus'
import ConfirmReturn from '@/modals/ConfirmReturn'

export default {
  mixins: [formatsCurrency, returnStatus],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    sku () {
      return get(this.item, 'orderItem.variant.sku', '')
    },
    productName () {
      return get(this.item, 'orderItem.productName')
    },
    quantity () {
      return get(this.item, 'quantity', 0)
    },
    status () {
      return this.getReturnRequestItemStatusText(this.item.status)
    },
    areActionsAvailable () {
      return (
        this.item.status !== 'completed' &&
        this.item.status !== 'returnExpired' &&
        this.item.status !== 'pendingReturn' &&
        this.item.status !== 'returnDenied'

      )
    },
    isPendingReturn () {
      return this.item.status === 'pendingReturn'
    }
  },
  methods: {
    async handleStatusChange (status) {
      if (status === 'returnDenied') {
        try {
          await this.$modal.openFormModal({
            title: 'Refuser le retour',
            fields: [{ name: 'rejectionMessage', label: 'Le motif du refus sera communiqué au client.' }],
            initialValues: { rejectionMessage: '' },
            confirmLabel: 'Confirmer',
            cancelLabel: 'Annuler',
            onSubmit: async ({ values, confirm, setErrors }) => {
              setErrors({})

              if (!values.rejectionMessage) {
                setErrors({
                  rejectionMessage: ['Ce champ est requis.']
                })
                return
              }

              this.$emit('click', { id: this.item.id, status, rejectionMessage: values.rejectionMessage })
              confirm()
            }
          })
        } catch (error) {
          if (error === 'ACTION_CANCELLED') {
            return
          }

          this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
        }
      } else if (status === 'returnConfirmed') {
        const confirmReturn = await this.$modal.open(ConfirmReturn, {
          item: this.item
        })

        this.$emit('click', { returnRequestItemId: this.item.id, status, ...confirmReturn })
      } else {
        try {
          await this.$modal.openConfirmModal({
            title: 'Accepter le retour',
            message: 'Veuillez confirmer que vous acceptez le retour de ce produit.',
            confirmLabel: 'Confirmer',
            cancelLabel: 'Annuler'
          })
        } catch {
          return
        }
        this.$emit('click', { id: this.item.id, status })
      }
    }
  }
}
</script>
